import React from "react";
import { Link, graphql } from "gatsby";
import Header from "../components/header";
import voorstelling from "../images/voorstellingen-nl.png";
import media from "../images/media.png";
import circus from "../images/geschiedenis-nl.png";
import tour from "../images/tour-tickets copy.png";
import contact from "../images/contact.png";
import "../styles/styles.css";
var slugify = require("../helpers");

const IndexPage = ({ data }) => {
  const mediaLink = () => {
    const url = "/media/" + slugify(data.allMysqlAlbums.edges[0].node.title_nl);
    return (
      <Link to={url} aria-label="Circus Ronaldo Media bibliotheek">
        <img className="homeImage media" src={media} alt="media" />
      </Link>
    );
  };
  return (
    <div>
      <Header back="false" lang taal="nl" />
      <nav>
        <Link
          to="/voorstellingen"
          aria-label="Meer info over de voorstellingen van Circus Ronaldo">
          <img className="homeImage" src={voorstelling} alt="voorstellingen" />
        </Link>
        {mediaLink()}
        <Link to="/circus_of_theater">
          <img
            className="homeImage circus"
            src={circus}
            alt="Circus of Theater"
          />
        </Link>
        <Link
          to="/tour_en_tickets"
          aria-label="Bekijk hier een lijst met alle tickets en opkomende voorstellingen">
          <img className="homeImage tour" src={tour} alt="Tour en Tickets" />
        </Link>
        <Link to="/contact" aria-label="Contacteer ons">
          <img className="homeImage contact" src={contact} alt="contact" />
        </Link>
      </nav>
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMysqlAlbums {
      edges {
        node {
          id
          title_nl
        }
      }
    }
  }
`;
